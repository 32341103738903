<template>
  <div class="trace">
    <div class="trace_title">订单跟踪</div>
    <div>
      <el-steps direction="vertical" :active="active">
        <el-step v-for="(item, index) in trace" :key="index">
          <template slot="description">
            <div class="tracebox">
              <div>
                <span style="display: inline-block; width: 150px">{{
                  item.operation_time
                }}</span
                >{{ item.operation }}
              </div>
              <div>
                <span style="display: inline-block; width: 150px"></span
                >{{ item.reject }}
              </div>
            </div>
          </template>
        </el-step>
      </el-steps>
    </div>
  </div>
</template>

<script>
export default {
  props: ["active", "trace"]
}
</script>

<style>
.trace {
  background: #f7f7f7;
  border-radius: 4px;
}

.trace_title {
  padding-left: 20px;
  box-sizing: border-box;
  height: 50px;
  font-size: 14px;
}
</style>