<template>
  <el-steps :active="active" align-center finish-status="wait">
    <el-step v-for="(item, index) in steps" :key="index">
      <template slot="icon"
        ><img :src="active > index ? item.aicon : item.icon" class="stepicon"
      /></template>
      <template slot="title"
        ><img
          :src="active > index ? item.atitle : item.title"
          class="steptitle"
      /></template>
    </el-step>
  </el-steps>
</template>

<script>
export default {
  data () {
    return {
      steps: [ //步骤数据
        {
          "title": require('../../../assets/slices/tg2.png'),
          "atitle": require('../../../assets/slices/tg2.png'),
          "icon": require('../../../assets/slices/tg.png'),
          "aicon": require('../../../assets/slices/tg.png')
        }, {
          "title": require('../../../assets/slices/pt3.png'),
          "atitle": require('../../../assets/slices/pt2.png'),
          "icon": require('../../../assets/slices/pt4.png'),
          "aicon": require('../../../assets/slices/pt1.png')
        }, {
          "title": require('../../../assets/slices/nsh.png'),
          "atitle": require('../../../assets/slices/qk2.png'),
          "icon": require('../../../assets/slices/nsh2.png'),
          "aicon": require('../../../assets/slices/qk1.png')
        }, {
          "title": require('../../../assets/slices/yly.png'),
          "atitle": require('../../../assets/slices/ly2.png'),
          "icon": require('../../../assets/slices/yly2.png'),
          "aicon": require('../../../assets/slices/ly1.png')
        }, {
          "title": require('../../../assets/slices/ywc.png'),
          "atitle": require('../../../assets/slices/wc2.png'),
          "icon": require('../../../assets/slices/ywc2.png'),
          "aicon": require('../../../assets/slices/wc1.png')
        }
      ]
    }
  },
  props: ["active"],
  mounted () {
    console.log(this.active);
  }
}
</script>

<style>
.ygdtitle {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
}
.num {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.num span {
  margin-right: 30px;
  cursor: pointer;
}
.num span:hover {
  color: #ff1918;
}
.stepicon {
  width: 70px;
}
.el-step.is-horizontal .el-step__line {
  border-top: 2px dashed #ff1918;
  border-radius: 50%;
  height: 0;
  background-color: transparent;
}
</style>