<template>
  <div>
    <div>
      <div class="articleTitle">{{ article.title }}</div>
      <div class="xs">
        <span class="author">作者：{{ article.editor }}</span
        ><span>期刊：{{ article.name }}</span>
      </div>
      <p class="bt">附件</p>
      <div class="file" @click="downloadAttach">
        <i class="el-icon-paperclip"></i><a>点击下载附件</a>
      </div>
      <p class="bt">摘要</p>
      <div class="articletext">
        {{ article.summary }}
      </div>
      <p class="bt">关键词</p>
      <div class="articletext">
        {{ article.keyword }}
      </div>
      <p class="bt">备注</p>
      <div class="articletext">
        {{ article.remarks }}
      </div>
      <p class="bt" v-show="article.draftnot">录稿通知</p>
      <div class="file" @click="download" v-show="article.draftnot">
        <i class="el-icon-paperclip"></i><a>点击下载录稿通知</a>
      </div>
    </div>
    <slot name="bottom"></slot>
  </div>
</template>

<script>
import { downloadFile } from '@/API/orderstatus'
export default {
  props: ["article",],
  mounted () {
    console.log(this.article);
  },
  methods: {
    // 下载附件
    downloadAttach () {
      let Path = this.article.content
      console.log(Path);
      downloadFile(Path)
    },
    //下载录稿通知
    download () {
      let Path = this.article.draftnot
      downloadFile(Path)
    }
  }
}
</script>

<style>
/*文章样式*/

.articleTitle {
  height: 80px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 80px;
  text-align: center;
}

.xs {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
}

.bt {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.xs .author {
  margin-right: 10px;
}

.file {
  width: 50%;
  height: 40px;
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.file span {
  font-size: 14px;
}

.articletext {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin: 10px;
}
</style>