<template>
  <div style="padding: 20px">
    <div class="dytitle">
<!--      <span-->
<!--              v-for="(item, index) in titlelist"-->
<!--              :class="now == index ? 'dyactive' : ''"-->
<!--      >{{ item }}</span-->
<!--      >-->
    </div>

    <div class="num">
      订单编号：{{ num }}
<!--      <span style="margin-right: 20px; cursor: pointer" @click="back"-->
<!--      >返回列表</span-->
<!--      >-->
    </div>
  <div>
    <steps :active="active"></steps>
    <trace :active="traceactive" :trace="trace"></trace>
    <div class="tab2">
      <div class="articleTitle">{{ article.title }}</div>
      <div class="xs">
        <span class="author">作者：{{ article.editor }}</span
        ><span>期刊：{{ article.name }}</span>
      </div>
      <p class="bt">附件</p>
      <div class="file" @click="downloadAttach">
        <i class="el-icon-paperclip"></i><a>点击下载附件</a>
      </div>
      <p class="bt">摘要</p>
      <div class="articletext">
        {{ article.summary }}
      </div>
      <p class="bt">关键词</p>
      <div class="articletext">
        {{ article.keyword }}
      </div>
      <p class="bt">备注</p>
      <div class="articletext">
        {{ article.remarks }}
      </div>
      <div class="articletext">
        <el-button type="danger" @click="back">返回</el-button>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { orderStatusDetail, downloadFile } from '@/API/orderstatus'
import {checkarticle, checksteps, checktrace} from "../../../API/QKD/detail";
import steps from "../../DePenDent/components/steps";
import trace from "../../DePenDent/components/trace";
import articlecont from "../../DePenDent/components/articlecont";
export default {
  components: {
    steps,
    trace,
 //   articlecont,
  },
  data () {
    return {
      article: {//文章详情数据

      },
      trace: [],
      traceactive: 0,
    }

  },
  mounted () {
    let id = this.$route.query.wid
    checksteps(id).then(res => {
      // console.log(res,'------------状态------------');
       this.num = res.data.data.contributionnumber
      //alert(res.data.data.state);
      if (res.data.data.state == 71 || res.data.data.state == 72 || res.data.data.state == 82) {
        this.active = 0
      } else
      if (res.data.data.state == 0 || res.data.data.state == 1 || res.data.data.state == 81 || res.data.data.state == 6 || res.data.data.state == 54 || res.data.data.state == 55 || res.data.data.state == 44) {
        this.active = 2
      } else if (res.data.data.state == 2 || res.data.data.state == 21 || res.data.data.state == 22) {
        this.active = 3
      } else if (res.data.data.state == 30 || res.data.data.state == 31 || res.data.data.state == 5) {
        this.active = 4
      } else if (res.data.data.state == 32 || res.data.data.state == 47) {
        this.active = 4
      } else {
        this.active = 5
      }


    })
    checktrace(id).then(res => {

      this.trace = res.data.data
      // console.log(this.trace,'订单详细状态----------')
      // 如果作者投稿失败 且 缴费成功 作者重新申请不回再次缴费
      // debugger;
      for(var i = 0; i < res.data.data.length;i++){

        if(this.trace[i].operation=='期刊驳回'){
          //  alert(this.trace[i].operation);
          return    this.flags=true
        }
        // if(this.trace[i].operation.indexof('缴费成功') >= 0 && this.trace[i].ispay == '1'){
        //   this.flag = false
        // }

      }
      this.traceactive = this.trace.length - 1

    })
    checkarticle(id).then(res => {
      this.article = res.data.data
    })

    orderStatusDetail(this.$route.query.wid).then(res => {
      if (res.data.code == 0) {
        this.article = res.data.data
      }
    })

  },
  methods: {
    // 下载附件
    downloadAttach () {
      let Path = this.article.content
      console.log(Path);
      downloadFile(Path)
    },
    back () {
      this.$router.push({ path: '/Services/OrderStatus', query: { id: 4 } });
    }
  }
}

</script>

<style>
  .dytitle {
    border-bottom: 1px solid #f7f7f7;
    font-size: 14px;
    line-height: 60px;
  }

  .dytitle span {
    margin: 0 10px;
    padding: 0 5px;
    display: inline-block;
  }
</style>